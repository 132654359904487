import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="Elfonze Technologies recently were invited and delivered the Keynote session at the Nemko, Carlsbad, California, US "
        paragraph="Lab as a Service (LaaS): A Look into the Future of Global Lab Certifications."
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/elf_insider/elfonze-session-nemko.jpg" alt="Elfonze_Nemko_Session" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                            Elfonze Technologies recently were invited and delivered the Keynote session at the Nemko, Carlsbad, California, US 2024 Certification and Market Access Seminar on 18-19 September.  The title of the Keynote was "Lab as a Service (LaaS): A Look into the Future of Global Lab Certifications" and covered the journey of the typical product engineer struggling to get his company's products global certifications updated and completed on time and the solution that Elfonze Technologies is developing - Synth EMC AI - an AI based platform that will alert customers of ever changing certifications across the globe with a co-pilot to enable more parallel lab resource sharing.  The Seminar was sold out to capacity with standing room only and had customers like SpaceX, Texas Instruments, Cisco, Fellowes, Cubic, Viasat, Western Digital.
                          </p>
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              <a href="#0">#Nemko</a>,
                              <a href="#0">#LaaS</a>,
                              <a href="#0">#Seminar</a>,
                              <a href="#0">#Globallabcertifications</a>,
                              <a href="#0">#Keynotesession</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies Keynote at Nemko Event, Carlsbad, CA</title>
      <meta key="description" name="description"
        content="Elfonze Technologies recently were invited and delivered the Keynote session at the Nemko, Carlsbad, California, US 2024 Certification and Market Access Seminar on 18-19 September." />
    </>
  )
}

export default BlogDetailsDark;
